






















































































































































































































































import { RequestPaging, timeFormat } from '@/utils/util'
import { Component, Vue } from 'vue-property-decorator'
import config from '@/config'
import LsDialog from '@/components/ls-dialog.vue'
import PopoverInput from '@/components/popover-input.vue'
import LsPagination from '@/components/ls-pagination.vue'
import {
    apiOrderDetail, // 订单信息
    apiDeliveryRecordList, // 配送历史
    apiRemark, // 添加备注
    apiChangeDate // 更改配送日期
} from '@/api/delivery'

@Component({
    components: {
        LsDialog,
        LsPagination,
        PopoverInput
    }
})
export default class Category extends Vue {
    timeFormat = timeFormat

    activeName = 'orderInfo'
    createRemarkDialog = false // 订单备注弹窗
    changeDateVisible = false // 修改配送日期弹窗
    config = config
    loading = true
    pager = new RequestPaging()
    delivery_record_id = ''
  
    orderInfo = {} // 订单信息
    orderRemark = '' // 订单备注
    delivery_date = ''
    apiDeliveryRecordList = apiDeliveryRecordList
  
    // 切换tabs
    tabsClick(tab: Object) {
        console.log(this.activeName)
    }
    // 打开订单备注弹窗
    open(row:any) {
        this.orderRemark = ""
        this.createRemarkDialog = true
        this.delivery_record_id = row.id
    }
    // 关闭弹窗
    closeRemark() {
        this.createRemarkDialog = false
        this.changeDateVisible = false
    }

    // 打开日期弹窗
    openDateDialog(row:any) {
        this.delivery_date = row.delivery_date
        this.delivery_record_id = row.id
        this.changeDateVisible = true
        
    }
    // 更改配送时间
    handleRedate() {
        if(!this.delivery_date) {
            this.$message.error("请选择配送时间")
            
        }
        let params = {
            delivery_record_id: this.delivery_record_id,
            delivery_date: Number(Date.parse(this.delivery_date + ' 00:00')) / 1000
        }
        apiChangeDate(params).then(res => {
            this.getDeliveryRecordList()
        })
        console.log("date", this.delivery_date)
    }

    // 提交订单备注
    submitOrderRemark() {
        console.log(this.orderRemark)
        if (!this.orderRemark) {
            this.$message.error('请输入订单备注')
            return;
        }
        let params = {
            delivery_record_id: this.delivery_record_id,
            remark: this.orderRemark
        }
        apiRemark(params).then(res => {
            this.$message.success("添加成功！")
            this.orderRemark = ''
            this.getDeliveryRecordList()
            this.createRemarkDialog = false
        })
    }
    
    // 获取配送历史
    getDeliveryRecordList(page?: number) {
        page && (this.pager.page = page)
        let params = {
            community_id: this.$route.query.community_id,
            deliveryman_id: this.$route.query.deliveryman_id,
            order_goods_id: this.$route.query.order_goods_id
        }
        this.pager.request({
            callback: apiDeliveryRecordList,
            params: {
                ...params
            }
        })
        this.changeDateVisible = false
      
    }
    // 获取订单详情信息
    getOrderDetail() {
        this.loading = true
        let params = {
            community_id: this.$route.query.community_id,
            deliveryman_id: this.$route.query.deliveryman_id,
            order_goods_id: this.$route.query.order_goods_id
        }
        apiOrderDetail(params).then((res: any) => {
            this.orderInfo = res
            this.$nextTick(() => {
                this.loading = false
            })
            console.log('62173617', res)
        })
    }
    // 初始化数据
    created() {
        this.getOrderDetail()
        this.getDeliveryRecordList()
    }
}
